@use "globals" as *;

.badge {
  display: inline-flex;
  align-items: center;
  font-size: 0.8rem;
  padding: var(--gutter-xs) var(--gutter-sm);
  font-weight: 500;
  letter-spacing: 0.025rem;
  border-radius: var(--radius-sm);
  backdrop-filter: blur(8px);
  transform: scale(1);
  min-height: 32px;
  opacity: 0.9;
  white-space: pre;
  transition: var(--time) var(--curve-fast);

  @include breakpoint(sm) {
    font-size: 0.7rem;
  }

  &:hover {
    opacity: 1;
    transition: var(--time) var(--curve-fast);
  }

  &.xs {
    font-size: 0.6rem;
    min-height: unset;
    border-radius: var(--radius-xs);
    padding: var(--gutter-xs) var(--gutter-sm);

    .icon {
      width: 0.8rem;
      height: 0.8rem;
    }
  }

  &.sm {
    font-size: 0.7rem;
    min-height: unset;
    border-radius: var(--radius-xs);
    padding: var(--gutter-xs) var(--gutter-sm);
  }

  &.md {
    font-size: 1rem;
    padding: var(--gutter-sm) var(--gutter);
  }

  &.lg {
    font-size: 1.2rem;
    padding: var(--gutter-sm) var(--gutter);
  }

  &-label {
    display: inline-flex;
    align-items: center;
    gap: var(--gutter-xs);
  }

  &-value {
    display: inline-flex;
    align-items: center;
    border-left: 1px solid rgba(var(--primary-bg-color-rgb), 0.5);
    padding-left: var(--gutter-sm);
    margin-left: var(--gutter-sm);
  }

  &-vertical {
    flex-direction: column;
    text-align: center;

    .badge-value {
      border-left: 0;
      border-top: 1px solid;
      margin: 0.15rem 0 0 0;
      padding: 0.15rem 0 0 0;
    }
  }

  &-text {
    border: 0;
    padding: 0;
    min-height: unset;

    .badge-label {
      font-weight: 300;
    }

    .badge-value {
      margin: 0;
      padding-left: 0.4rem;
    }
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: var(--radius-xs);
    margin-right: var(--gutter-sm);
  }

  &-clear {
    margin-left: var(--gutter-sm);
  }

  &-primary {
    background: var(--theme-primary-color);
    color: var(--white-color);
  }

  &-primary-light {
    background: rgba(var(--theme-primary-color-rgb), 0.1);
    color: var(--theme-primary-color);

    .badge-value {
      border-color: rgba(var(--theme-primary-color-rgb), 0.25);
    }
  }

  &-secondary {
    background: var(--secondary-color);
    color: var(--white-color);
  }

  &-secondary-light {
    background: var(--secondary-light-color);
    color: var(--secondary-color);

    .badge-value {
      border-color: rgba(var(--secondary-color-rgb), 0.25);
    }
  }

  &-white {
    background: rgba(var(--white-color-rgb), 0.85);
    color: var(--overlay-color);
  }

  &-white-light {
    background: rgba(var(--white-color-rgb), 0.1);
    color: var(--white-color);

    .badge-value {
      border-color: rgba(var(--white-color-rgb), 0.25);
    }
  }

  &-light {
    border: 1px solid rgba(var(--primary-text-color-rgb), 0.1);
    background: transparent;
    color: var(--primary-text-color);

    .badge-value {
      border-color: rgba(var(--primary-text-color-rgb), 0.25);
    }
  }

  &-inverse {
    border: 1px solid rgba(var(--primary-text-color-rgb), 0.1);
    background: var(--primary-text-color);
    color: var(--primary-bg-color);

    .badge-value {
      border-color: rgba(var(--primary-bg-color-rgb), 0.25);
    }
  }

  &-gold {
    background: var(--gold-color);
    color: var(--white-color);

    .badge-value {
      border-color: rgba(var(--white-color-rgb), 0.25);
    }
  }

  &-gold-light {
    background: rgba(var(--gold-color-rgb), 0.1);
    color: var(--gold-color);

    .badge-value {
      border-color: rgba(var(--gold-color-rgb), 0.25);
    }
  }

  &-silver {
    background: var(--silver-color);
    color: var(--white-color);

    .badge-value {
      border-color: rgba(var(--white-color-rgb), 0.25);
    }
  }

  &-silver-light {
    background: rgba(var(--silver-color-rgb), 0.1);
    color: var(--silver-color);

    .badge-value {
      border-color: rgba(var(--silver-color-rgb), 0.25);
    }
  }

  &-bronze {
    background: var(--bronze-color);
    color: var(--white-color);

    .badge-value {
      border-color: rgba(var(--white-color-rgb), 0.25);
    }
  }

  &-bronze-light {
    background: rgba(var(--bronze-color-rgb), 0.1);
    color: var(--bronze-color);

    .badge-value {
      border-color: rgba(var(--bronze-color-rgb), 0.25);
    }
  }

  &-green {
    background: rgba(var(--green-color-rgb), 0.85);
    color: var(--white-color);

    .badge-value {
      border-color: rgba(var(--green-color-rgb), 0.25);
    }
  }

  &-green-light {
    background: rgba(var(--green-color-rgb), 0.1);
    color: var(--green-color);

    .badge-value {
      border-color: rgba(var(--green-color-rgb), 0.25);
    }
  }

  &-orange {
    background: rgba(var(--orange-color-rgb), 0.85);
    color: var(--white-color);

    .badge-value {
      border-color: rgba(var(--orange-color-rgb), 0.25);
    }
  }

  &-orange-light {
    background: rgba(var(--orange-color-rgb), 0.1);
    color: var(--orange-color);

    .badge-value {
      border-color: rgba(var(--orange-color-rgb), 0.25);
    }
  }

  &-red {
    background: rgba(var(--red-color-rgb), 0.85);
    color: var(--white-color);

    .badge-value {
      border-color: rgba(var(--red-color-rgb), 0.25);
    }
  }

  &-red-light {
    background: rgba(var(--red-color-rgb), 0.1);
    color: var(--red-color);

    .badge-value {
      border-color: rgba(var(--red-color-rgb), 0.25);
    }
  }

  &-yellow {
    background: rgba(var(--yellow-color-rgb), 0.85);
    color: var(--white-color);

    .badge-value {
      border-color: rgba(var(--yellow-color-rgb), 0.25);
    }
  }

  &-yellow-light {
    background: rgba(var(--yellow-color-rgb), 0.1);
    color: var(--yellow-color);

    .badge-value {
      border-color: rgba(var(--yellow-color-rgb), 0.25);
    }
  }

  &-blue {
    background: var(--blue-color);
    color: var(--white-color);

    .badge-value {
      border-color: rgba(var(--blue-color-rgb), 0.25);
    }
  }

  &-blue-light {
    background: rgba(var(--blue-color-rgb), 0.1);
    color: var(--blue-color);

    .badge-value {
      border-color: rgba(var(--blue-color-rgb), 0.25);
    }
  }

  &-gradient {
    color: var(--white-color);
    background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  }

  &-gradient-secondary-light {
    color: var(--blue-color);
    background: linear-gradient(to right, rgba(var(--secondary-color-rgb), 0.2), rgba(var(--blue-color-rgb), 0.2));
  }
}

.badge-variation {
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  padding: 0;

  .badge-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    padding: var(--gutter-sm);
    margin-right: var(--gutter-sm);

    i {
      font-size: 1rem;
      margin: 0;
    }
  }

  &.badge-positive {
    color: var(--green-color);

    .badge-icon {
      background-color: var(--green-light-color);
      border-radius: 50%;
    }
  }

  &.badge-negative {
    color: var(--red-color);

    .badge-icon {
      background-color: var(--red-light-color);
      border-radius: 50%;
    }
  }

  &.badge-neutral {
    color: var(--invert-color);

    .badge-icon {
      background-color: var(--invert-light-color);
      border-radius: 50%;
    }
  }
}
