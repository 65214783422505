@use "globals" as *;

body {
  font-family: var(--font-family-default);
  font-weight: 400;
  background-color: var(--primary-bg-color);
  background-image: linear-gradient(to top, rgba(var(--secondary-bg-color-rgb), 0.1), transparent);
  color: var(--primary-text-color);
  height: 100%;
  overflow-x: hidden;
  min-height: calc(100vh + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  --theme-primary: var(--primary-color-rgb);
  --theme-secondary: var(--secondary-color-rgb);
  --sticky-top: var(--navbar-height);

  @include breakpoint(md) {
    padding-bottom: calc(var(--navbar-height-mobile) + env(safe-area-inset-bottom));
    --sticky-top: var(--navbar-height-mobile);
  }
}

a {
  color: var(--primary-text-color);
  opacity: 1;
  text-decoration: none;
  cursor: pointer;
  transition: 0.35s opacity;
}

img {
  max-width: 100%;
}

b,
strong {
  font-weight: 600;
}

ul {
  padding: 0;
  margin: 0;
}

mark {
  background-color: rgba(var(--primary-color-rgb), 0.1);
  color: var(--primary-color);
}

.gutter {
  margin: var(--gutter);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--font-family-heading);
  line-height: 1.25;
  font-weight: 600;
}

ul {
  list-style: none;
  margin: 0;
}

hr {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

::-moz-selection {
  background: rgba(var(--accent-color-rgb), 0.1);
  color: var(--accent-color);
}

::selection {
  background: rgba(var(--accent-color-rgb), 0.1);
  color: var(--accent-color);
}

.landing {
  .nav-left ul,
  .footer-content {
    display: none;
  }
}
