@use "globals" as *;

.invoice-ticket {
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--secondary-bg-color);
  border-radius: var(--radius-lg);
  border: 1px solid var(--border-color);
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    position: relative;
    clear: both;
  }

  &-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--gutter-md);
    border-bottom: 1px dashed var(--border-color);

    &:before,
    &:after {
      background-color: var(--primary-bg-color);
      position: absolute;
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      border: 1px solid var(--border-color);
      border-radius: 100%;
      bottom: 0;
      margin-bottom: -10px;
      z-index: 100;
    }

    &:before {
      left: 0;
      margin-left: -10px;
    }
    &:after {
      right: 0;
      margin-right: -10px;
    }

    h6 {
      font-size: 1rem;
      font-weight: 500;
      font-family: var(--font-family-mono);
      opacity: 0.7;
    }
  }

  &-body {
    padding: var(--gutter-md);
    border-bottom: 1px dashed var(--border-color);
  }

  &-cover {
    img {
      width: 100%;
      height: auto;
      border-radius: var(--radius-lg);
      object-fit: cover;
    }
  }

  &-footer {
    padding: var(--gutter);
    margin-top: auto;
  }

  &-logo {
    width: 200px;
    max-width: 200px;
  }
}
