@use "globals" as *;

.chat {
  display: flex;
  flex-direction: column;

  &-wrapper {
    display: grid;
    grid-template-columns: var(--chat-sidebar-width) 1fr;
    border-radius: var(--radius);
    height: var(--chat-height);
    @include shadow-border;
    --chat-height: 90svh;
    --chat-body-height: calc(
      var(--chat-height) - var(--chat-header-height) - var(--chat-footer-height) - var(--chat-overview-height)
    );
    --chat-sidebar-width: 260px;
    --chat-overview-height: 100px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--gutter);
    padding: var(--gutter);
    border-bottom: 1px solid var(--border-color);

    @include breakpoint(sm) {
      padding: var(--gutter-xs) var(--gutter-sm) var(--gutter-xs) 0;

      .button-icon {
        padding-right: var(--gutter-xs);
      }

      .button-label {
        display: none;
      }
    }

    .avatar {
      --avatar-size: 48px;

      @include breakpoint(md) {
        --avatar-size: 42px;
      }

      @include breakpoint(sm) {
        --avatar-size: 36px;
      }
    }
  }

  &-sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gutter-xs);
    padding: var(--gutter-sm);
    max-height: var(--chat-height);
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid var(--border-color);
  }

  &-list-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5rem 0.5rem;
    border-radius: var(--radius-sm);
    font-size: 0.9rem;
    cursor: pointer;
    transition: var(--time-fast) all var(--curve-fast);

    &.active {
      background: rgba(var(--primary-text-color-rgb), 0.1);
      color: var(--primary-text-color);
    }

    &:hover:not(.active) {
      background: rgba(var(--primary-text-color-rgb), 0.1);
      border-color: var(--border-color);
      transition: var(--time-fast) all var(--curve-fast);
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--gutter);
    flex: 1;
    height: var(--chat-body-height);
    max-height: var(--chat-body-height);
    overflow-y: auto;
    transition: var(--time-faster) all var(--curve-fast);

    .message {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--gutter-sm);
      background-color: rgba(var(--primary-text-color-rgb), 0.1);
      border-radius: var(--radius);
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 1.5;
      max-width: 70%;
      padding: var(--gutter);
      margin-bottom: var(--gutter-xs);
      word-break: break-word;

      @include breakpoint(sm) {
        max-width: 80%;
        font-size: 0.85rem;
        padding: 0.6rem;
      }

      &:first-child {
        margin-top: auto;
      }

      &.self {
        background-color: rgba(var(--primary-color-rgb), 0.15);
        color: var(--primary-text-color);
        align-self: flex-end;

        .message-attachment {
          background-color: rgba(var(--primary-color-rgb), 0.15);
          border-color: rgba(var(--primary-color-rgb), 0.15);

          &:hover {
            background-color: rgba(var(--primary-color-rgb), 0.3);
          }

          &-header {
            border-color: rgba(var(--primary-color-rgb), 0.3);
          }
        }

        &.last {
          border-bottom-right-radius: 0;
        }
      }

      &:not(.self) {
        &.last {
          border-bottom-left-radius: 0;
        }
      }

      &.last {
        margin-bottom: var(--gutter-md);

        &:last-child {
          margin-bottom: 0;
        }
      }

      &.message-system {
        font-weight: 500;
      }

      /* Chat Message Colors */
      &.message-primary {
        background-color: rgba(var(--primary-color-rgb), 0.1);
        color: var(--primary-color);
      }

      &.message-blue {
        background-color: rgba(var(--blue-color-rgb), 0.1);
        color: var(--blue-color);
      }

      &.message-green {
        background-color: rgba(var(--green-color-rgb), 0.1);
        color: var(--green-color);
      }

      &.message-orange {
        background-color: rgba(var(--orange-color-rgb), 0.1);
        color: var(--orange-color);
      }

      &.message-red {
        background-color: rgba(var(--red-color-rgb), 0.1);
        color: var(--red-color);
      }
    }
  }

  &-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--gutter);
    border-top: 1px solid var(--border-color);
    margin-top: auto;

    form {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: var(--gutter-sm);

      textarea {
        width: 100%;
        max-height: 200px;
        border: 1px solid var(--border-color);
        resize: none;
        transition: var(--time-fast) all var(--curve-fast);

        &:focus {
          border-color: rgba(var(--primary-text-color-rgb), 0.3);
          transition: var(--time-fast) all var(--curve-fast);
        }
      }
    }
  }

  &-archive {
    &.active {
      background: rgba(var(--primary-color-rgb), 0.1);
      color: var(--primary-color);

      .avatar-placeholder {
        background: rgba(var(--primary-color-rgb), 0.1);
        color: var(--primary-color);
      }
    }
  }
}

.message-attachments {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--gutter-sm);
}

.message-attachment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--radius-sm);
  background-color: rgba(var(--primary-text-color-rgb), 0.1);
  border: 1px solid var(--border-color);
  flex: 1;
  transition: var(--time-fast) all var(--curve-fast);

  &:hover {
    background-color: rgba(var(--primary-text-color-rgb), 0.3);
    transition: var(--time-fast) all var(--curve-fast);
  }

  &-header {
    display: flex;
    align-items: center;
    text-align: left;
    letter-spacing: 0.025rem;
    text-transform: uppercase;
    padding: 0.4rem var(--gutter-sm);
    width: 100%;
    font-size: 0.75rem;
    font-weight: 300;
    opacity: 0.7;
    border-bottom: 1px solid var(--border-color);
  }

  &-body {
    display: flex;
    align-items: center;
    padding: var(--gutter-sm);
    gap: 0.75rem;
  }

  &-image {
    position: relative;
    border-radius: var(--radius-xs);
    overflow: hidden;
    width: var(--thumbnail-size);
    height: var(--thumbnail-size);
    aspect-ratio: 1 / 1;
    flex-shrink: 0;
    --thumbnail-size: 42px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

/* Responsive */
.chat-mobile {
  height: unset;
  max-height: unset;

  &.chat-detail {
    grid-template-columns: 1fr;

    .chat-sidebar {
      display: none;
    }
  }

  &.chat-list {
    grid-template-columns: 1fr;

    .chat {
      display: none;
    }
  }

  .chat-sidebar {
    border-right: 0;
  }
}
