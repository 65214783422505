@use "globals" as *;

footer {
  background: var(--secondary-bg-color);
  border-top: 1px solid var(--border-color);
}

.footer-content {
  padding: var(--gutter-md) var(--gutter);
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--gutter);

  @include breakpoint(sm) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.footer-logo {
  @include breakpoint(sm) {
    grid-column: span 2;
  }

  svg {
    width: 64px;
    height: 64px;
  }
}

.footer-socials {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gutter-md);

  li {
    i {
      font-size: 2rem;
      color: var(--primary-text-color);
      transition: 0.5s all;
      opacity: 0.5;

      &:hover {
        opacity: 1;
        transition: 0.5s all;
      }
    }
  }
}

.footer-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gutter-sm);
  padding: 0;

  li {
    a {
      color: var(--primary-text-color);
      font-size: 0.9rem;
      opacity: 0.75;
      transition: var(--time) all ease-in-out;

      &:hover {
        color: var(--primary-text-color);
        opacity: 1;
      }
    }
  }
}
