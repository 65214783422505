@use "globals" as *;

.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(var(--secondary-bg-color-rgb), 0.8);
  backdrop-filter: blur(10px);
  height: var(--navbar-height);
  border-bottom: 1px solid var(--border-color);
  z-index: 30;
  transition-property: width, left;
  transition-duration: var(--time-fast);
  transition-timing-function: var(--curve-fast);

  &.has-sidebar {
    &.nav-collapsed {
      width: calc(100% - var(--sidebar-width-icon));
      left: var(--sidebar-width-icon);
    }

    &.nav-expanded {
      width: calc(100% - var(--sidebar-width));
      left: var(--sidebar-width);
    }

    .nav-logo {
      @media screen and (min-width: $breakpoint-lg) {
        display: none;
      }
    }
  }

  @include breakpoint(lg) {
    width: 100% !important;
    height: var(--navbar-height-mobile);
    left: 0 !important;
  }

  &-item {
    display: inline-flex;
    align-items: center;
    font-size: 0.8rem;
    letter-spacing: -0.02rem;
    gap: var(--gutter-sm);
    border-radius: var(--radius-sm);
    padding: var(--gutter-sm) var(--gutter-sm);

    .icon {
      height: 1rem;
      opacity: 0;
      width: 0;
      transform: translate(0.5rem);
      transition: var(--time-fast) var(--curve-fast);
    }

    &:hover,
    &[data-state="open"],
    &.active {
      .icon {
        opacity: 1;
        width: 1rem;
        transform: translate(0);
        transition: var(--time-fast) var(--curve-fast);
      }
    }

    &:hover,
    &:focus,
    &.active {
      background: rgba(var(--white-color-rgb), 0.1);
    }
  }

  &-menu {
    background: var(--secondary-bg-color);
    border: 1px solid var(--border-color);
    border-radius: var(--radius-lg);
    padding: var(--gutter-sm);
  }

  .button-icon {
    padding: 0.8rem;
  }
}

.nav-list-item {
  display: flex;
  align-items: center;
  gap: var(--gutter);
  border-radius: var(--radius-sm);
  padding: var(--gutter-sm);

  .icon {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
  }
}

.theme-toggle {
  i {
    margin: 0 !important;
  }
}
