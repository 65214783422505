@use "globals" as *;

.card-blog {
  .card-image {
    position: relative;
    aspect-ratio: 16 / 10;
    border-radius: calc(var(--radius) - #{var(--gutter-xs)});
    overflow: hidden;
  }

  .card-content {
    padding: var(--gutter) var(--gutter-sm) var(--gutter-sm) var(--gutter-sm);
    text-align: left;

    h4 {
      font-size: 1.25rem;
      line-height: 1.25;
      text-wrap: balance;
    }

    p {
      font-size: 0.9rem;
      opacity: 0.9;
    }
  }
}
