@use "globals" as *;

.home-hero {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 0 !important;
}

.home-grid-wrapper {
  position: relative;
  z-index: 0;
  pointer-events: none;

  .overlay {
    background-color: transparent;
    z-index: 20;

    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 0;
      width: 160px;
      height: 100%;
      z-index: 1;
    }

    &:before {
      left: 0;
      background: linear-gradient(
        to left,
        rgba(var(--primary-bg-color-rgb), 0) 0%,
        rgba(var(--primary-bg-color-rgb), 1) 90%
      );
    }

    &:after {
      right: 0;
      background: linear-gradient(
        to right,
        rgba(var(--primary-bg-color-rgb), 1) 10%,
        rgba(var(--primary-bg-color-rgb), 0) 100%
      );
    }
  }
}

.home-grid {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--gutter-sm);
  mask-image: linear-gradient(to top, var(--primary-bg-color), transparent 100%);
  margin-bottom: -0.5rem;

  .marquee {
    gap: var(--gutter-sm);

    .rfm-marquee,
    .rfm-initial-child-container {
      gap: var(--gutter-sm);
    }
  }
}
