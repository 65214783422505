@use "globals" as *;

.shepherd-element {
  background-color: var(--secondary-bg-color);
  color: var(--primary-text-color);
}

.shepherd-content {
  border-radius: var(--radius);
  overflow: hidden;
  --progress-height: 4px;
}

.shepherd-has-title .shepherd-content .shepherd-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--secondary-bg-color);
  border-bottom: 1px solid var(--border-color);
  padding: calc(var(--gutter) - var(--progress-height)) var(--gutter) var(--gutter) var(--gutter);
  gap: var(--gutter-sm);

  h3 {
    color: var(--primary-text-color);
    font-weight: 600;
  }
}

.shepherd-text {
  color: var(--primary-text-color);
  padding: var(--gutter);

  p {
    font-size: 0.95rem;
    line-height: 1.5;
    margin-bottom: var(--gutter);
    opacity: 0.9;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.shepherd-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--secondary-bg-color);
  border-top: 1px solid var(--border-color);
  padding: var(--gutter-sm) var(--gutter);

  .button {
    min-width: 120px;
  }
}

.shepherd-button:not(:disabled):hover {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.shepherd-arrow:before {
  background-color: var(--secondary-bg-color) !important;
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  color: var(--primary-text-color);
  font-weight: 300;
  margin-top: -#{var(--gutter-sm)};
  opacity: 0.4;
  transition: var(--time) var(--curve-fast);

  &:hover {
    color: var(--primary-text-color);
    opacity: 0.8;
    transition: var(--time) var(--curve-fast);
  }
}

.tour-steps {
  font-weight: 400;
  opacity: 0.9;
}

.tour-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--progress-height);
  background-color: rgba(var(--primary-color-rgb), 0.15);

  > div {
    height: 100%;
    background-color: var(--primary-color);
  }
}

.tour-active {
  .nav-mobile {
    flex-wrap: wrap;
  }
}
