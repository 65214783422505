@use "globals" as *;

.accordion {
  background: var(--secondary-bg-color);
  border-radius: var(--radius);
  overflow: hidden;
  @include shadow-border;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.25rem;
    text-align: left;
    font-weight: 600;
    padding: 0.75rem var(--gutter);
    cursor: pointer;

    h5 {
      display: flex;
      align-items: center;
      gap: var(--gutter-sm);
      font-size: 1.1rem;
      font-family: var(--font-family-default);
      font-weight: 500;
      text-wrap: balance;
      margin: 0;

      @include breakpoint(md) {
        font-size: 1rem;
      }

      @include breakpoint(sm) {
        font-size: 0.9rem;
      }
    }
  }

  &-body {
    background: var(--secondary-bg-color);
    padding: 0 var(--gutter);
    text-align: left;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    border-radius: 0 0 var(--radius) var(--radius);
    border-top: 1px solid var(--border-color);
    transition: var(--time-fast) var(--curve-fast);

    &.open {
      max-height: 9999px;
      opacity: 1;
      padding: var(--gutter);
    }

    p {
      line-height: 1.5;
      opacity: 0.9;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      > a {
        color: var(--primary-color);
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }
}
