@use "globals" as *;

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  pointer-events: none;
}

.overlay-bottom {
  background: linear-gradient(
    to bottom,
    rgba(var(--overlay-color-rgb), 0) 0%,
    rgba(var(--overlay-color-rgb), 0.9) 100%
  );
}

.overlay-primary {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(var(--primary-color-rgb), 0) 0%,
    rgba(var(--primary-color-rgb), 0.9) 100%
  );
  z-index: 9;
}

.overlay-secondary {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(var(--secondary-color-rgb), 0) 0%,
    rgba(var(--secondary-color-rgb), 0.9) 100%
  );
  z-index: 9;
}

.overlay-gradient {
  background: linear-gradient(
    to bottom,
    rgba(var(--primary-color-rgb), 0.9) 0%,
    rgba(var(--secondary-color-rgb), 0.9) 100%
  );
}

.overlay-gradient-h {
  background: linear-gradient(
    to right,
    rgba(var(--primary-color-rgb), 0.9) 0%,
    rgba(var(--secondary-color-rgb), 0.9) 100%
  );
}

.overlay-musixmatch {
  background: linear-gradient(
    to right,
    rgba(var(--musixmatch-orange-color-rgb), 1) 0%,
    rgba(var(--musixmatch-red-color-rgb), 1) 100%
  );

  mask-image: linear-gradient(to bottom, transparent 50%, black 95%);
  -webkit-mask-image: linear-gradient(to bottom, transparent 50%, black 95%);
}

.overlay-clip-home {
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 1) 100%);
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 1) 100%);
}
