@use "globals" as *;

.placeholder {
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  background-color: rgba(var(--primary-text-color-rgb), 0.02);
  border: 1px solid var(--border-color);
  border-radius: var(--placeholder-radius);
  padding: var(--placeholder-padding);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gutter);
  text-align: center;
  color: rgba(var(--primary-text-color-rgb), 0.5);
  font-size: 1.25rem;
  font-weight: 500;
  --placeholder-radius: 1rem;
  --placeholder-padding: var(--gutter);

  @include breakpoint(sm) {
    padding: var(--gutter);
  }

  i,
  svg {
    color: var(--neutral-color);
    pointer-events: none;
  }

  &-avatar {
    border-radius: 50%;
  }

  &.xs {
    --placeholder-radius: 0.2rem;
    --placeholder-padding: 0;

    .icon {
      width: 1rem;
      height: 1rem;
    }
  }

  &.sm {
    --placeholder-radius: 0.4rem;
    --placeholder-padding: 0;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &.md {
    --placeholder-radius: 0.5rem;
    --placeholder-padding: var(--gutter-sm);

    .icon {
      width: 2rem;
      height: 2rem;
    }
  }

  &.lg {
    --placeholder-radius: 1rem;
    --placeholder-padding: var(--gutter);

    .icon {
      width: 4rem;
      height: 4rem;
    }
  }

  &.xl {
    --placeholder-radius: 1.5rem;
    --placeholder-padding: var(--gutter-lg);

    .icon {
      width: 8rem;
      height: 8rem;
    }
  }

  &-green {
    background-color: rgba(var(--green-color-rgb), 0.1);
    border-color: rgba(var(--green-color-rgb), 0.3);
    color: var(--green-color);

    i,
    svg {
      color: var(--green-color);
      opacity: 0.8;
    }
  }

  p {
    text-wrap: balance;
  }
}
