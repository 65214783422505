@use "globals" as *;

.mobile-nav {
  position: fixed;
  height: calc(var(--navbar-height-mobile) + env(safe-area-inset-bottom));
  width: 100%;
  left: env(safe-area-inset-left);
  right: env(safe-area-inset-right);
  bottom: 0;
  padding-bottom: env(safe-area-inset-bottom);
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: rgba(var(--secondary-bg-color-rgb), 0.9);
  backdrop-filter: blur(4px);
  border-radius: 0;
  box-shadow: 0 -1px 1px var(--border-color);
  overflow: hidden;
  z-index: 40;
  transform: translateY(0);
  transition: var(--time) ease-in-out;

  @include breakpoint(lg) {
    display: flex;
  }

  ul {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: var(--navbar-height-mobile);

    li {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      gap: var(--gutter-xs);
      height: var(--navbar-height-mobile);
      opacity: 0.5;

      span:not(.icon) {
        font-size: 0.65rem;
      }

      .icon {
        width: 1.4rem;
        height: 1.4rem;
      }

      &.active {
        opacity: 1;

        span:not(.icon) {
          font-weight: 600;
        }
      }
    }
  }
}
