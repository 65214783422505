@use "globals" as *;

#rank {
  p {
    margin-bottom: var(--gutter);
  }
}

.rank {
  &-table {
    display: flex;
    flex-direction: column;
    gap: var(--gutter-sm);
    max-width: 576px;
    margin: 0 auto;

    &-row {
      display: inline-flex;
      align-items: center;
      gap: var(--gutter);
      border-radius: var(--radius);
      padding: 0.75rem;
      border: 1px solid var(--border-color);
      transition: var(--time-fast) all var(--curve-fast);

      &:hover {
        transform: scale(0.98);
        opacity: 0.8;
        transition: var(--time-fast) all var(--curve-fast);
      }
    }

    .rank-avatar {
      position: relative;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
    }

    .rank-index {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      font-size: 0.85rem;
      border-radius: 50%;
      background-color: rgba(var(--primary-color-rgb), 0.1);
      color: var(--primary-color);
      font-weight: 500;

      &:first-child {
        background-color: transparent;
      }
    }

    .rank-table-row-1 {
      background-color: rgba(var(--gold-color-rgb), 0.1);
      color: var(--gold-color);
      border-color: var(--gold-color);
    }

    .rank-table-row-2 {
      background-color: rgba(var(--silver-color-rgb), 0.1);
      color: var(--silver-color);
      border-color: var(--silver-color);
    }

    .rank-table-row-3 {
      background-color: rgba(var(--bronze-color-rgb), 0.1);
      color: var(--bronze-color);
      border-color: var(--bronze-color);
    }

    .rank-table-row-4,
    .rank-table-row-5 {
      background-color: rgba(var(--primary-color-rgb), 0.1);
      color: var(--primary-color);
      border-color: var(--primary-color);
    }

    .rank-table-row-1,
    .rank-table-row-2,
    .rank-table-row-3 {
      font-weight: 500;

      .rank-index {
        background-color: transparent;
      }
    }
  }
}
