@use "globals" as *;

#artists {
  .artist-quote {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: rgba(var(--secondary-bg-color-rgb), 0.4);
    border-radius: var(--radius-lg);
    height: 100%;
    padding: var(--gutter);
    gap: var(--gutter-md);
    @include shadow-border;

    .artist-info {
      position: relative;
      display: flex;
      align-items: center;
      gap: 0.75rem;
      border-top: 1px solid var(--border-color);
      padding-top: var(--gutter);
      margin-top: var(--gutter-md);

      h5 {
        font-size: 1rem;
        font-weight: 500;
        text-align: left;
        margin: 0;

        @include breakpoint(md) {
          font-size: 0.9rem;
        }
      }
    }

    .artist-image {
      position: relative;
      width: 42px;
      height: 42px;
      border-radius: var(--radius-full);

      @include breakpoint(md) {
        width: 32px;
        height: 32px;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: var(--radius-full);
      }
    }

    .artist-video {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: var(--radius);

      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: var(--radius);
      }
    }

    q {
      font-size: 0.9rem;
      line-height: 1.5;
      text-align: left;
      font-weight: 400;
      text-wrap: balance;

      @include breakpoint(md) {
        font-size: 0.8rem;
      }
    }
  }

  .slider-artists {
    .swiper-slide {
      height: auto;
      padding: var(--gutter);
      cursor: grab;

      @include breakpoint(sm) {
        padding: var(--gutter) var(--gutter-xs);
      }
    }
  }
}
