@use "globals" as *;

.icon {
  display: inline-flex;
  width: 1rem;
  height: 1rem;

  svg {
    width: 100%;
    height: 100%;
  }

  &.xs {
    width: 0.75rem;
    height: 0.75rem;
  }

  &.sm {
    width: 0.875rem;
    height: 0.875rem;
  }

  &.md {
    width: 1.5rem;
    height: 1.5rem;
  }

  &.lg {
    width: 2rem;
    height: 2rem;
  }

  &.xl {
    width: 3rem;
    height: 3rem;
  }
}

.icon-verified {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: var(--white-color);

  svg {
    width: 100%;
    height: 100%;
    color: var(--blue-color);
  }
}

.icon-primary {
  background-color: rgba(var(--primary-color-rgb), 0.1);
  color: var(--primary-color);
}

.icon-secondary {
  background-color: rgba(var(--secondary-color-rgb), 0.1);
  color: var(--secondary-color);
}

.icon-green {
  background-color: rgba(var(--green-color-rgb), 0.1);
  color: var(--green-color);
}

.icon-tiktok {
  background-color: rgba(var(--tiktok-primary-color-rgb), 0.1);
  color: var(--tiktok-primary-color);
}

.icon-orange {
  background-color: rgba(var(--orange-color-rgb), 0.1);
  color: var(--orange-color);
}

.icon-blue {
  background-color: rgba(var(--blue-color-rgb), 0.1);
  color: var(--blue-color);
}

.icon-gold {
  background-color: rgba(var(--gold-color-rgb), 0.1);
  color: var(--gold-color);
}
