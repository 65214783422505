@use "globals" as *;

.wrapper {
  margin-top: calc(var(--navbar-height) + var(--gutter));
  margin-bottom: 8rem;

  @include breakpoint(md) {
    margin-top: calc(var(--navbar-height-mobile) + var(--gutter));
  }
}

.container {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--gutter-md);
  padding-right: var(--gutter-md);

  @include breakpoint(lg) {
    padding-left: var(--gutter);
    padding-right: var(--gutter);
  }
}
