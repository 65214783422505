@use "globals" as *;

.alert {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;
  border: 1px solid;
  padding: var(--gutter);
  gap: var(--gutter);
  border-radius: var(--radius);

  &-inline {
    flex-direction: row;
    align-items: center;

    @include breakpoint(sm) {
      flex-direction: column;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.25rem;
    text-align: left;
    font-weight: 600;
    cursor: pointer;

    h4 {
      display: inline-flex;
      align-items: center;
      gap: var(--gutter-sm);
      font-size: 1.2rem;
      margin: 0;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.sm {
    font-size: 0.8rem;
    padding: var(--gutter-sm);
    border-radius: var(--radius-sm);
  }

  &-content {
    display: flex;
    flex-direction: column;
    text-wrap: balance;

    p {
      word-break: break-word;
      margin-bottom: var(--gutter);

      &:last-child {
        margin: 0;
      }
    }
  }

  &-icon {
    text-align: center;

    .alert-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }

  &-primary {
    background: var(--primary-light-color);
    border-color: rgba(var(--primary-color-rgb), 0.3);
    backdrop-filter: blur(4px);
  }

  &-theme-primary {
    background: rgba(var(--theme-primary-color-rgb), 0.1);
    border-color: rgba(var(--theme-primary-color-rgb), 0.3);
    color: var(--theme-primary-color);
    backdrop-filter: blur(4px);
  }

  &-primary-light {
    background: var(--primary-light-color);
    border-color: rgba(var(--primary-color-rgb), 0.3);
    color: var(--primary-color);
    backdrop-filter: blur(4px);
  }

  &-secondary {
    background: rgba(var(--secondary-color-rgb), 0.1);
    border-color: rgba(var(--secondary-color-rgb), 0.3);
    color: var(--secondary-color);
  }

  &-primary-bg {
    background: var(--primary-bg-color);
    border-color: var(--border-color);
  }

  &-secondary-bg {
    background: var(--secondary-bg-color);
    border-color: var(--border-color);
  }

  &-ghost {
    background: transparent;
    border-color: var(--border-color);
  }

  &-blue {
    background: rgba(var(--blue-color-rgb), 0.1);
    border-color: rgba(var(--blue-color-rgb), 0.3);
    color: var(--blue-color);
  }

  &-orange {
    background: rgba(var(--orange-color-rgb), 0.1);
    border-color: rgba(var(--orange-color-rgb), 0.3);
    color: var(--orange-color);
  }

  &-yellow {
    background: rgba(var(--yellow-color-rgb), 0.1);
    border-color: rgba(var(--yellow-color-rgb), 0.3);
    color: var(--yellow-color);
  }

  &-red {
    background: rgba(var(--red-color-rgb), 0.1);
    border-color: rgba(var(--red-color-rgb), 0.3);
    color: var(--red-color);
  }

  &-green {
    background: rgba(var(--green-color-rgb), 0.1);
    border-color: rgba(var(--green-color-rgb), 0.3);
    color: var(--green-color);
  }

  &-gold-light {
    background: rgba(var(--gold-color-rgb), 0.1);
    border-color: rgba(var(--gold-color-rgb), 0.3);
    color: var(--gold-color);
  }

  &-blue-light {
    background: rgba(var(--blue-color-rgb), 0.1);
    border-color: rgba(var(--blue-color-rgb), 0.3);
    color: var(--blue-color);
  }
}
