@use "globals" as *;

.certifications-disc {
  animation: spin 2s linear infinite paused;
}

.certifications-spin {
  &:hover {
    .certifications-disc {
      animation-play-state: running;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
