@use "globals" as *;

.tiktok-grid {
  @include breakpoint(sm) {
    scroll-snap-type: proximity;
  }
}

.tiktok-card {
  position: relative;
  overflow: hidden;
  aspect-ratio: 9 / 16;
  max-height: calc(100vh - var(--navbar-height-mobile) * 2 - var(--gutter) * 2);
  border-radius: var(--radius-lg);
  background-color: var(--secondary-bg-color);
  scroll-snap-align: center;
  border: 1px solid transparent;
  transition: var(--time-fast) var(--curve-fast);

  &:hover {
    border-color: rgba(var(--primary-text-color-rgb), 0.2);
    transition: var(--time-fast) var(--curve-fast);

    .tiktok-card-cover {
      opacity: 0;
      transition: var(--time-fast) var(--curve-fast);
    }

    .tiktok-card-video {
      opacity: 1;
      transition: var(--time-fast) var(--curve-fast);
    }
  }

  &-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid rgba(var(--primary-text-color-rgb), 0.5);
    box-shadow: 0 4px 8px rgba(var(--shadow-color-rgb), 0.1);
  }

  &-cover,
  &-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: var(--radius);
    overflow: hidden;
    pointer-events: none;
    transition: var(--time-fast) var(--curve-fast);

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-bg {
    filter: blur(32px);
    z-index: 5;
  }

  &-cover {
    z-index: 10;
  }

  &-video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 15;
    transition: var(--time-fast) var(--curve-fast);

    .video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gutter-sm);
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: rgba(var(--secondary-bg-color-rgb), 0.8);
    backdrop-filter: blur(4px);
    border-radius: 0 0 var(--radius) var(--radius);
    z-index: 20;
    pointer-events: none;
  }
}

.tiktok-detail {
  position: relative;

  .info {
    a {
      color: var(--theme-primary-color);
      font-weight: 500;
      text-decoration: underline;
    }
  }

  &-preview {
    .modal-detail-header {
      border-bottom: 0;
    }
  }
}
