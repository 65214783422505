@use "globals" as *;

.avatar-pro {
  background: linear-gradient(to bottom, rgba(var(--secondary-color-rgb), 0.9), rgba(var(--blue-color-rgb), 0.9));
  overflow: visible;
  --avatar-gap: 0.6rem;

  img {
    width: calc(var(--avatar-size) - var(--avatar-gap));
    height: calc(var(--avatar-size) - var(--avatar-gap));
    top: calc(var(--avatar-gap) / 2);
    left: calc(var(--avatar-gap) / 2);
    border-radius: 50%;
  }

  .verified {
    position: absolute;
    right: 0.6rem;
    bottom: 0.1rem;
    z-index: 19;

    svg {
      width: 24px;
      color: var(--blue-color);
    }
  }

  &.avatar-placeholder {
    background: transparent;

    .placeholder {
      background: linear-gradient(
        to bottom,
        rgba(var(--secondary-color-rgb), 0.1),
        rgba(var(--secondary-color-rgb), 0.3)
      );

      .icon svg {
        color: var(--secondary-color);
      }
    }
  }
}

.profile-sidebar-header {
  .verified {
    svg {
      width: 16px;
    }
  }
}

.chat-header,
.chat-list-item {
  .avatar-pro {
    --avatar-gap: 0.3rem;

    .verified {
      right: 0;

      svg {
        width: 16px;
      }
    }
  }
}

.curator-card-pro {
  background: rgba(var(--secondary-color-rgb), 0.05);
  border: 1px solid rgba(var(--secondary-color-rgb), 0.1);
  transition: var(--time-faster) all var(--curve-fast);

  &:hover {
    background: rgba(var(--secondary-color-rgb), 0.1);
    border-color: rgba(var(--secondary-color-rgb), 0.2);
    transition: var(--time-faster) all var(--curve-fast);
  }
}
