@use "globals" as *;

.plans-header {
  position: relative;
  border-radius: var(--radius-lg);
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
  }

  .overlay {
    background: linear-gradient(
      0deg,
      rgba(var(--primary-bg-color-rgb), 1) 0%,
      rgba(var(--primary-bg-color-rgb), 0.5) 100%
    );
    z-index: 1;
  }
}

.plan {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: rgba(var(--secondary-bg-color-rgb), 0.1);
  border-radius: var(--radius-lg);
  padding: var(--gutter);
  border: 1px solid var(--border-color);

  @include breakpoint(sm) {
    padding: var(--gutter-md);
  }

  &-caption {
    display: block;
    font-size: 0.9rem;
    font-weight: 400;
    text-wrap: balance;
    margin: var(--gutter-sm) 0 0 0;
    opacity: 0.9;
  }

  &-sale {
    position: relative;
    display: inline-block;
    font-size: 1.8rem;
    color: var(--primary-text-color);
    opacity: 0.5;

    &:before {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: var(--primary-text-color);
      top: 50%;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h2 {
      font-size: 1.8rem;
      font-weight: 600;
      margin: 0;
    }

    span {
      font-size: 1.8rem;
    }
  }

  &-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--gutter) 0;

    h3 {
      font-size: 1.8rem;
      font-weight: 600;
      margin: 0;
    }

    h5 {
      margin: 0;
    }

    p {
      margin-top: var(--gutter-xs);
    }
  }

  &-save {
    font-size: 1.5rem;
    margin-top: var(--gutter-sm);
  }

  &-features {
    display: flex;
    flex-direction: column;
    gap: var(--gutter-sm);

    li {
      display: flex;
      align-items: flex-start;
      font-size: 0.9rem;

      span {
        margin-right: var(--gutter-sm);
      }
    }
  }

  &-action {
    width: 100%;
    margin-top: auto;
    padding-top: var(--gutter);
  }

  .button,
  button {
    width: 100%;
    margin-top: auto;
  }
}

.plan-most-popular {
  box-sizing: -box;
  border: 0;
  border-radius: 0 0 var(--radius) var(--radius);
  box-shadow: inset 0 0 0 1px var(--most-popular-color);
  --most-popular-color: var(--primary-color);

  @include breakpoint(lg) {
    margin-top: var(--gutter-lg);
  }

  &:before {
    content: "Most Popular";
    position: absolute;
    top: 1px;
    left: 0;
    background: var(--most-popular-color);
    color: var(--white-color);
    padding: var(--gutter-sm) var(--gutter-sm);
    font-size: 0.85rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    border-radius: var(--radius) var(--radius) 0 0;
    width: 100%;
    text-align: center;
    transform: translateY(-100%);
  }
}

.plan-compare {
  border-radius: var(--radius);
  @include shadow-border;
  overflow: hidden;

  thead th:nth-child(2),
  tbody td:nth-child(2) {
    background-color: rgba(var(--primary-color-rgb), 0.05);
    border-color: rgba(var(--primary-color-rgb), 0.3);
    border: 1px solid rgba(var(--primary-color-rgb), 0.5);
    font-weight: 500;
  }

  thead th:nth-child(2) {
    background-color: rgba(var(--primary-color-rgb), 0.9);
    color: var(--white-color);
  }

  td {
    @include breakpoint(sm) {
      font-size: 0.85rem;
    }
  }
}

$colors: (primary, secondary, tertiary, gold, blue);

@each $color in $colors {
  .#{"plan-" + $color} {
    background: rgba(var(--#{$color}-color-rgb), 0.05);
    transition: var(--time-slow) var(--curve-out);

    &:hover,
    &.active {
      background: rgba(var(--#{$color}-color-rgb), 0.2);
      border-color: rgba(var(--#{$color}-color-rgb), 0.3);
      transition: var(--time-slow) var(--curve-out);
    }

    .plan-header {
      span {
        color: var(--#{$color}-color);
      }
    }

    &.plan-most-popular {
      --most-popular-color: var(--#{$color}-color);
    }

    .plan-action {
      .button {
        background: var(--#{$color}-color);
        color: var(--white-color);
        border-color: var(--#{$color}-color);
      }
    }
  }
}
