@use "globals" as *;

.table {
  width: 100%;
  color: var(--primary-text-color);
  margin-bottom: var(--gutter);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-sm);
  border-collapse: separate;
  border-spacing: 0;

  thead {
    th {
      font-size: 0.9rem;
      font-weight: 500;
      text-align: left;
      background-color: rgba(var(--border-color-rgb), 0.2);
      border: none;
      border-bottom: 1px solid var(--border-color);
      backdrop-filter: blur(16px);
      letter-spacing: 0.015rem;

      @include breakpoint(sm) {
        font-size: 0.8rem;
      }

      &:first-child {
        border-top-left-radius: var(--radius-sm);
        // border: 1px solid var(--border-color);
      }

      &:last-child {
        border-top-right-radius: var(--radius-sm);
      }
    }
  }

  th,
  td {
    color: var(--primary-text-color);
    padding: var(--gutter-sm) 0.75rem;
    vertical-align: middle;

    @include breakpoint(sm) {
      padding: var(--gutter-sm);
    }
  }

  td {
    font-size: 0.9rem;
    border: none;
    border-bottom: 1px solid var(--border-color);

    @include breakpoint(sm) {
      font-size: 0.8rem;
    }
  }

  th:not(:last-child),
  td:not(:last-child) {
    border-right: none;
  }

  tr {
    transition: var(--time-fast) var(--curve-fast);

    &:only-child {
      td {
        border-bottom: none;
      }
    }

    &:last-child {
      td {
        border-bottom: none;

        &:first-child {
          border-bottom-left-radius: var(--radius-sm);
        }

        &:last-child {
          border-bottom-right-radius: var(--radius-sm);
        }
      }
    }

    &:hover {
      background: rgba(var(--primary-text-color-rgb), 0.05);
      transition: var(--time-fast) var(--curve-fast);
    }
  }

  &-sm {
    th,
    td {
      font-size: 0.85rem;
      padding: 0.4rem;
      height: 30px;
      overflow: hidden;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
}

.table-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gutter-sm);
  margin: 0;

  .button-label {
    white-space: nowrap;
  }

  @include breakpoint(lg) {
    .button-label {
      display: none;
    }

    .button i,
    .button svg {
      margin-right: 0;
    }
  }
}

.table-filters {
  display: flex;
  align-items: center;
  gap: var(--gutter-lg);

  @include breakpoint(sm) {
    flex-wrap: wrap;
    gap: var(--gutter);
  }

  .button {
    height: 46px;
  }
}

.button-faceted-filter {
  border: 1px dashed var(--border-color);

  &:hover {
    background-color: var(--input-color);
  }

  .button-label {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
  }
}

.table-rounded-none {
  border-radius: 0 !important;

  th,
  td {
    border-radius: 0 !important;
  }
}
